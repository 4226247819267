@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap);

body {
  margin: 0;
  font-family: 'Bangers', 'Open Sans Condensed', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  font-family: 'Bangers', 'Open Sans Condensed', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  transition: background ease .25s;
}
:root {
  --bg-col-blue: rgba(162,215,236,255);
  --color-accent-red: #ee5454;
  --color-accent-pink: deeppink;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: left;
}

/*.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 500px;
  overflow: hidden;
  background-size: cover !important;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,0.65) 100%), url(./media/Banner_Sketch_2.png) no-repeat center center scroll;
  background-color: var(--bg-col-blue);
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-title {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
}*/

h1 {
  font-size: 10rem;
}

.App-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 75vh;
  overflow: hidden;
  background: 
/*    radial-gradient(
      ellipse at center, 
      rgba(0,0,0,0) 0%, 
      rgba(0,0,0,0) 37%, 
      rgba(0,0,0,0.65) 100%), */
    url(/static/media/HH_BANNER_BACKPLATE.6ed532e1.png);
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-color: rgba(162,215,236,255);
  background-color: var(--bg-col-blue);
  font-size: calc(10px + 2vmin);
  color: white;
}

/*HEADER BANNER STUFF*/
.banner-titles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.left-banner-wrap,
.right-banner-wrap {
  min-width: 200px;
  width: 30%;
  margin: 2em 0.5em 0.5em 0.5em;
}

.left-banner-img,
.right-banner-img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 640px) {
  .App-header {
    background-image: url(/static/media/banner_sky.9e8a0c01.png);
    justify-content: center;
    height: 50vh;
  }
  .banner-titles {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 75%;
  }
  .left-banner-wrap,
  .right-banner-wrap {
    width: 80%;
    margin: auto;
    max-height: 45%;
  }
}


.App-header-title {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
}

h1 {
  font-size: 10rem;
}

h2 {
  font-size: 4.5rem;
  font-weight: 400;
}

h3 {
  font-size: 3rem;
  font-weight: 400;
}

h4 {
  font-size: 2rem;
  font-weight: 400;
}

p {
  font-size: 2rem;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  h2 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.5rem;
  }
}

#coming-soon {
  width: 90%;
  margin: auto;
}

.img-holding-logo {
  width: 100%;
  max-width: 2400px;
  min-width: 500px;
  margin: auto;
}

@media screen and (max-width: 730px) {
  .img-holding-logo {
    min-width: unset;
    width: unset;
    max-height: 50%;
    max-height: 70vh;
    max-width: 100%;
  }
}

a {
  text-decoration: none;
}

.full-width {
  width: 100%;
}

.width-80 {
  width: 80%;
}

.half-width {
  width: 50%;
}

.text-center {
  text-align: center;
}

.text-no-margin-top {
  margin-top: 0;
}

.text-no-margin-bottom {
  margin-bottom: 0;
}

.word-break-all {
  word-break: break-all;
}

.fix-top {
  position: absolute;
  top: 0;
  width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-c-row-md-col {
  display: flex;
  flex-direction: row;
}

.flex-c-row-md-col-rev {
  display: flex;
  flex-direction: row-reverse;
}

.flex-c-col-md-row {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 730px) {
  .flex-c-row-md-col {
    display: flex;
    flex-direction: column;
  }

  .flex-c-row-md-col-rev {
    display: flex;
    flex-direction: column;
  }

  .flex-c-col-md-row {
    display: flex;
    flex-direction: row;
  }
}

.posn-relative {
  position: relative;
}

.btn-type-a {
  height: 3em;
  border: 3px solid black;
  background-color: rgba(162,215,236,255);
  background-color: var(--bg-col-blue);
  margin: 5px;
  padding: 0;
  font-size: 2rem;
  text-align: center;
  /*font-weight: bold;*/
}

.btn-type-a:hover,
.btn-type-a:active {
  background-color: deeppink;
  color: white;
  cursor: pointer;
}

.flex-row .btn-type-a {
  min-width: 14em;
}

.btn-type-b {
  height: 3em;
  border: 5px solid black;
  background-color: deeppink;
  margin: 5px;
  padding: 0;
  font-size: 3rem;
  text-align: center;
  color: white;
  /*font-weight: bold;*/
}

.btn-type-b:hover,
.btn-type-b:active {
  background-color: white;
  color: deeppink;
  cursor: pointer;
}

.btn-type-c {
  height: 3em;
  border: 5px solid black;
  background-color: transparent;
  margin: 5px;
  padding: 0;
  font-size: 3rem;
  text-align: center;
  color: white;
  /*font-weight: bold;*/
}

.btn-type-c:hover,
.btn-type-c:active {
  background-color: white;
  color: deeppink;
  cursor: pointer;
}

.btn-wide {
  width: 75%;
}

@media screen and (max-width: 730px) {
  .btn-wide {
    width: 90%;
  }
}

.btn-short {
  height: 1.5em;
}

.btn-ele {
  padding: 0;
  margin: auto 5px;
}

.btn-logo {
  display: flex;
  align-items: center;
  height: 100%;
}

.btn-social-logo {
  width: 50px;
}

.social-logo-img {
  width: 100%;
  height: 2em;
  line-height: 2em;
}

.social-logo-img:hover,
.social-logo-img:active {
  background-color: rgba(0,0,0,0.1);
  border-radius: 30%;
}

.btn-logo svg,
.btn-social-logo svg {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.social-btn-group {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  margin: 5px 0.5em;
}

.btn-type-nav {
  height: 3em;
  border: 3px solid transparent;
  background-color: transparent;
  margin: 5px;
  padding: 0;
  font-size: 2rem;
  text-align: center;
  /*font-weight: bold;*/
  width: 100%;
}

.btn-type-nav:hover {
  /*border: 3px solid black;*/
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.link-pink,
.link-pink:visited {
  color: deeppink;
  color: var(--color-accent-pink);
  text-decoration: none;
}

.link-pink:hover,
.link-pink:active {
  color: rgba(162,215,236,255);
  color: var(--bg-col-blue);
}

.link-hover-white:hover,
.link-hover-white:active {
  color: white;
}

.navbar-wrap {
  height: 2rem;
  align-items: center;
  justify-content: flex-end;
  margin: 1em 0.5em;
  padding: 0 1em;
}

.navbar-homepage-logo {
  max-width: 10em;
  max-height: 100px;
}

.navbar-links-wrap {
  align-items: center;
  justify-content: center;
  max-width: 80%;
}

.link-button-wrap {
  width: 10em;
  max-width: 25%;
  height: 100%;
  text-align: center;
}

.section-wrap {
  width: 100%;
  margin: 2em 0;
}

.section-content {
  align-items: center;
  justify-content: space-evenly;
  margin: 1rem;
}

.section-content-img {
  width: 30%;
  max-width: 400px;
  min-width: 250px;
}

.message-post {
  min-width: 30%;
  max-width: 60%;
  margin: 1rem;
}

@media screen and (max-width: 730px) {
  .section-content-img {
    width: 95%;
  }

  .message-post {
    width: 95%;
    max-width: 400px;
  }
}


{/* CONNECTING */}

button.connect-modal-button {
  width: 75%;
}

.connect-modal-open {
  width: 85%;
  margin: auto;
}

.connect-modal-open h3 {
  margin: auto;
}

.connect-modal-container button {
  font-size: 1.75em;
}

.connect-modal-underlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 100, 100, 0.25);
}

.connect-modal-container {
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  flex-direction: column;
  width: 80%;
  max-width: 600px;
}

.connect-buttons-container {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  background-color: white;
}

.close-button-container {
  background-color: transparent;
  width: 10%;
  min-width: 5%;
  margin: 0 0 2px auto;
  height: 35px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.btn-close-button {
  width: 100%;
  max-width: 100%;
  height: auto;
  background-color: transparent;
  margin: 5px;
}

.close-button-icon {
  position: relative;
}

.close-button-icon svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  margin: auto;
}

.connect-modal-button {
  width: 75%;
}

.connect-modal-button.btn-connected {
  border-color: #33FF00;
}

.connect-modal-button.btn-activating {
  border-color: orangered;
}

button.connect-modal-button{
  border: 5px solid black;
  cursor: pointer;
  position: relative;
  background-color: white;
  margin: 5px 5px;
}

button.connect-modal-button:hover,
button.connect-modal-button:focus {
  border: 5px solid deeppink;
}

.connect-modal-button-content {
  position: relative;
  margin: auto;
  width: 80%;
  height: 95%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  color: black;
  background-color: white;
}

/*.connect-modal-open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}*/

.connect-modal-open-button-content {
  display: flex;
  max-width: 90%;
  flex-direction: row;
  align-items: safe center;
  justify-content: space-evenly;
  margin: auto;
}



{/*MINTING*/}

.mint-section {
  width: 80%;
  background-color: rgba(162,215,236,255);
  background-color: var(--bg-col-blue);
  border: 5px solid black;
  margin: 2em auto;
  text-align: center;
}

.mint-bar-container {
  width: 100%;
}

.mint-bar-content-wrap {
  width: 85%;
  margin: auto;
}

.mint-status {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 730px) {
  .mint-section {
    width: 95%;
  }
}

@media screen and (min-width: 730px) {
  .mint-bar-title {
    font-size: 6rem;
    margin: 1rem auto;
  }
}

@media screen and (min-width: 1200px) {
  .mint-bar-title {
    font-size: 7rem;
    margin: 1rem auto;
  }
}

.mint-bar-message-first-line,
.mint-bar-message-next-line {
  margin: 0.25em auto;
}

.mint-bar-form {
  width: 100%;
  min-width: 70px;
  margin: auto;
}

.mint-bar-input-wrapper {
  width: 100%;
}

.mint-bar-number-input-wrap {
  margin: 10px auto;
}

.mint-bar-number {
  display: block;
  width: 90px;
  height: 80px;
  margin: auto;
  border: 3px solid black;
  font-size: 50px;
  text-align: center;
  font-weight: bold;
  color: navy;
  background-color: white;
  font-family: "Bangers", "Open Sans Condensed", sans-serif;
}




{/*ROADMAP*/}

#id-roadmap {
  background-color: rgba(162,215,236,255);
  background-color: var(--bg-col-blue);
  padding: 1em 0;
}

.roadmap-item-wrap {
  margin: 1.5em 2em;
  padding: 1em;
  background-color: rgba(255,255,255, 0.4);
  text-align: left;
}

.roadmap-item-wrap:hover {
  background-color: rgba(255,255,255, 0.6);
}

.roadmap-item-title {
  color: black;
  margin: 0.1rem 2rem;
}

.roadmap-item-subtitle {
  margin: 1rem 2rem 0 2rem;
  color: deeppink;
  color: var(--color-accent-pink);
  font-weight: normal;
}

.roadmap-item-text {
  margin: 0.5rem 2rem 0 2rem;
  font-weight: bold;
}

.panels-wrap {
  width: 95%;
}

.panel-item-wrap {
  width: 50%;
}

.panel-item-img {
  width: 100%;
}

@media screen and (max-width: 730px) {
  .panel-item-wrap {
    width: 100%;
  }
}

.four-image-grid-wrap {
  margin: 1rem;
}

.side-by-side-wrap {
}

.side-by-side-item {
  width: 45%;
  max-width: 300px;
  padding: 0.2rem;
}

.side-by-side-img {
  display: block;
  width: 100%;
  margin: auto;
}

.team-gallery {
  width: 90%;
  align-items: flex-start;
  justify-content: space-evenly;
}

@media screen and (max-width: 730px) {
  .team-gallery {
    align-items: center;
  }
}

.link-card-wrap {
  min-width: 200px;
  width: 30%;
}

/*@media screen and (max-width: 730px) {
  .link-card-wrap {
    width: 50%;
  }
}*/

.link-card-anchor {
  width: 100%;
}

.link-card-image-wrap {
  width: 100%;
  margin: auto;
}

.link-card-img {
  display: block;
  width: 90%;
  margin: 0.5em auto;
}

.link-card-text {
  width: 90%;
  font-size: 1.25rem;
  margin: 0 auto 0.5em auto;
}

a.link-card-anchor,
a.link-card-anchor:visited {
  background: transparent;
  color: black;
  transition: background ease .25s;
  transition: color ease .25s;
}

a.link-card-anchor:hover {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: deeppink;
}

a.link-card-anchor:active {
  background: rgba(0, 255, 255, 0.15);
  cursor: pointer;
  color: deeppink;
}

.App-footer {
  margin: 5rem auto 1rem auto;
}
